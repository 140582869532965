@use '@angular/material' as mat;
@mixin side-nav-theme($theme) {
    $primary: map-get($theme, primary);
    $warn: map-get($theme, warn);
    $background: map-get($theme, accent);
    $foreground: map-get($theme, primary);
    html,
    body {
        height: 100%;
    }
    a,
    a:hover {
        color: mat.get-color-from-palette($primary);
        text-decoration: none;
    }
    .mat-list-item {
        padding-left: 5px !important;
    }
    .mat-list-item:hover,
    .orange-primary {
        color: mat.get-color-from-palette($primary);
    }
    .mat-list-item.active {
        color: mat.get-color-from-palette($primary);
        background: mat.get-color-from-palette($primary, 0.2);
        border-left: 5px solid mat.get-color-from-palette($primary);
        padding-left: 0 !important;
    }
    .mat-row:hover {
        background: mat.get-color-from-palette($primary, 0.8);
        cursor: pointer;
    }
    .total-count-icon {
        background-color: mat.get-color-from-palette($primary);
        box-shadow: 3px 3px 10px #ccc;
    }
    .error {
        color: mat.get-color-from-palette($primary);
    }
    table.mat-table {
        width: 100%;
    }
    .round-btn {
        border-radius: 100px !important;
        padding-left: 28px !important;
        padding-right: 28px !important;
    }
    .round-btn.btn-lg {
        font-size: 16px !important;
        padding: 5px 34px;
    }
    .primery-gradient-btn {
        background-image: linear-gradient(to right, #ee855d, #da512d);
        color: #fff;
    }
    .action-btn {
        background-color: #9095a3;
        color: #fff;
    }
    .purchase-detail .margin-top-bottom-15,
    .policy-detail .margin-top-bottom-15 {
        margin-top: -16px !important;
        margin-bottom: -16px !important;
    }
    .purchase-detail .border-left,
    .policy-detail .border-left {
        border-left: 1px solid #ccc;
    }
    .left-section,
    .right-section {
        padding-top: 15px;
        padding-bottom: 15px;
    }
    .purchase-detail .inner-container,
    .policy-detail .inner-container {
        padding: 15px 20px;
    }
    .title-label {
        font-weight: bold;
    }
    button,
    button:hover,
    button:focus {
        outline: none;
    }
    .mat-header-cell {
        font-size: 15px;
        font-weight: 700;
        color: #98bcd5;
    }
    .mat-row:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
    td.mat-cell,
    td.mat-footer-cell,
    th.mat-header-cell {
        border-bottom-style: none !important;
    }
    .sortby {
        border-radius: 100px !important;
        margin-left: 15px !important;
        margin-right: 15px !important;
    }
    .view-all {
        text-decoration: underline;
        color: #000;
    }
    .mat-simple-snackbar-action {
        color: #98bcd5;
    }
    .primary-color {
        color: #da512d;
    }
    .mat-paginator {
        background-color: transparent;
    }
    /*
        File Document controlles
    */
    .custom-file-label::after {
        display: none;
    }
    .custom-file-label.empty {
        color: #da512d;
    }
    .input-group-prepend .input-group-text {
        min-width: 180px;
        color: #98bcd5;
        background-color: #dfe7ed;
    }
    .input-group-text,
    .custom-file-label {
        padding: 0.675rem 0.75rem;
        height: auto;
    }

    .custom-file:hover .view-action,
    .custom-file:hover .cancel-action,
    .custom-file:hover .edit-action {
        display: block;
    }

    .custom-file .view-action,
    .custom-file .cancel-action,
    .custom-file .edit-action {
        z-index: 2;
        position: absolute;
        right: 0;
        top: 6%;
        color: #888;
        display: none;
    }

    .custom-file .cancel-action {
        right: 40px;
    }

    .custom-file .edit-action {
        right: 80px;
    }
    .carousel-indicators {
        bottom: -48px;
    }
    .carousel-indicators li {
        height: 14px;
        width: 14px;
        background-color: #98bcd5;
        border-radius: 50%;
        margin: 0 5px;
    }
    .carousel-indicators li.active {
        background-color: rgba(0, 0, 0, 0.87);
    }
    .carousel-control-prev-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
    }
    .carousel-control-next-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath d='M1.5 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
    }
    // Pagginator style
    .mat-paginator-range-actions .mat-paginator-icon {
        background-color: #fff;
        box-shadow: 0px 0px 2px #888;
    }
    .mat-paginator-range-actions .mat-icon-button {
        width: auto;
    }
    .mat-paginator-navigation-first .mat-paginator-icon {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }
    .mat-paginator-navigation-last .mat-paginator-icon {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }
    // UNCOMMENT TO RESIZE HEIGHT FOR INPUT OUTLINR
    // mat-form-field.mat-form-field.mat-form-field-appearance-outline>div.mat-form-field-wrapper>div.mat-form-field-flex>div.mat-form-field-infix {
    //     padding: 0.4em 0px
    // }
    // mat-form-field.mat-form-field.mat-form-field-appearance-outline>div.mat-form-field-wrapper>div.mat-form-field-flex>div.mat-form-field-infix>span.mat-form-field-label-wrapper {
    //     top: -1.5em;
    // }
    // .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    //     transform: translateY(-1.1em) scale(.75);
    //     width: 133.33333%;
    // }

    .ratecard-input .mat-form-field-wrapper,
    .limit-dialog-input .mat-form-field-wrapper {
        padding-bottom: 0.25rem;
    }

    .ratecard-textarea textarea.mat-input-element {
        resize: none;
    }

    .rc-checkbox {
        .mat-checkbox-layout {
            margin-bottom: 0px;
        }
    }
}

.request-ds,
.order-ds {
    .mat-tree-node {
        .mat-button-base {
            margin-left: 16px;
            font-size: 16px;
            width: calc(100% - 16px);
            text-align: left;
            height: 60px;
            line-height: 60px;

            .mat-icon {
                margin-left: 5px;
                margin-right: 5px;
            }

            .mat-button-ripple-round {
                opacity: 0;
            }
        }
    }

    .tree-parent-node:hover {
        background: rgba(0, 0, 0, 0.04);
        .mat-button-base {
            color: #f44336;
        }
    }

    .mat-tree-node {
        .mat-list-item {
            .mat-list-item-content {
                padding: 0 16px 0 49px;
            }
        }
    }
}

.pdf-currency {
    font-size: 12px;
}
